import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAMap from 'vue-amap';
import { Menu, MenuItem } from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/reset.scss';
import './assets/style/main.scss';

Vue.use(Menu)
Vue.use(MenuItem)
Vue.config.productionTip = false
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
    key: 'cc9bf4392010f00351b9f4a1dba6763e',
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.4'
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
