<template>
    <div id="app">
        <router-view  />
    </div>
</template>
<script>
export default {
    directives: {
  scroll: {
    bind: function (el){
      window.addEventListener('scroll', ()=> {
        if(document.body.scrollTop + window.innerHeight >= el.clientHeight) {
          console.log('load data');
        }
      })
    }
  }
}
}
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
</style>
