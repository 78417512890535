import Vue from 'vue'
import VueRouter from 'vue-router'
// import Index from '@/Index.vue'

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'Index',
        component: () => import('@/Index.vue'),
        redirect: '/Home',
        children: [
            {
                path: '/Home',
                name: 'Home',
                component: () => import('../views/Home.vue')
            },
            {
                path: '/Aboutme',
                name: 'Aboutme',
                component: () => import('../views/Aboutme.vue')
            },
            {
                path: '/Business',
                name: 'Business',
                component: () => import('../views/Business.vue')
            },
            {
                path: '/Hotnews',
                name: 'Hotnews',
                component: () => import('../views/Hotnews.vue')
            },
            {
                path: '/Partnerv',
                name: 'Partnerv',
                component: () => import('../views/Partnerv.vue')
            },
            {
                path: '/Proservice',
                name: 'Proservice',
                component: () => import('../views/Proservice.vue')
            },
            {
                path: '/Talent',
                name: 'Talent',
                component: () => import('../views/Talent.vue')
            },
        ]
    },

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
